import { useState, useEffect } from "react"
import {
  collection,
  query,
  where,
  getDocs,
  documentId,
} from "firebase/firestore"
import { db } from "../firebase/config"
const useUserEmail = name => {
  const [data, setData] = useState([])

  useEffect(() => {
    const fetchNav = async () => {
      if (name !== 0) {
        const q = query(
          collection(db, "users"),
          where(documentId(), "==", name)
        )
        const querySnapshot = await getDocs(q)
        let arr = {}
        querySnapshot.forEach(doc => {
          arr = doc.data()
        })
        setData(arr)
      }
    }

    fetchNav()
  }, [name])

  return data
}

export default useUserEmail
